<template>
  <validation-observer ref="observer">
    <v-form>
      <v-row>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Branch Number" rules="required|numeric">
            <v-text-field v-model="localItem.number" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNumber')"
                          :min="0" outlined type="number"/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Branch Name (EN)" rules="required|min:2|max:30">
            <v-text-field v-model="localItem.brand_name_en" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNameEn')" outlined/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Branch Name (AR)" rules="required|min:2|max:30">
            <v-text-field v-model="localItem.brand_name_ar" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNameAr')" outlined/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <kurcc-autocomplete :display-function="areaDisplayMethod" :filter="areaFilterByName" :items="areas"
                              :loading="fetchingAreas" :multiple="false"
                              :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.area')"
                              :selected-items.sync="localItem.area" outlined/>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Address" rules="required|min:2|max:300">
            <v-text-field v-model="localItem.address" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.address')" outlined/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Manager Name" rules="required|min:2|max:30">
            <v-text-field v-model="localItem.manager_name" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.managerName')"
                          outlined/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Manager Phone Number" rules="required">
            <v-text-field v-model="localItem.manager_phone_number" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.managerPhoneNumber')" outlined/>
          </validation-provider>
        </v-col>
        <v-col cols="6">
          <validation-provider v-slot="{ errors }" name="Manager Phone Number" rules="required">
            <v-text-field v-model="localItem.manager_phone_number" :error-messages="errors"
                          :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.managerPhoneNumber')" outlined/>
          </validation-provider>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { areaDisplayMethod, areaFilterByName } from '@/modules/site/helpers/utils'

export default {
  name: 'KurccBranchDialog',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    ValidationObserver,
    KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
  },
  data () {
    return {
      submittingData: false,
      fetchingAreas: false,
      areas: [],
      areaDisplayMethod,
      areaFilterByName
    }
  },
  computed: {
    localItem: {
      get () {
        return this.item
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    getAllConfigs () {
      this.fetchingAreas = true
      this.$store.dispatch('getAllConfigs').then(res => {
        this.areas = res.areas
      }).finally(() => {
        this.fetchingAreas = false
      })
    }
  },
  created () {
    this.getAllConfigs()
  }
}
</script>
